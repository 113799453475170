import React from "react"
import { Link } from "react-router-dom"
import { Badge, Input, Label } from "reactstrap"
import moment from "moment";

export  const columns = [
  {
      dataField: 'userId',
      text: 'Reference Id',
      sort: true,
      formatter: (row) => {
        return <Link to="/client/dashboard" className="text-dark fw-bold">
        {`EDOC-${row}`}
      </Link>
      }
  },
  {
      dataField: 'userFirstName',
      text: 'First Name',
      sort: true
  },
  {
      dataField: 'userLastName',
      text: 'Last Name',
      sort: false
  },
  // {
  //   dataField: 'total',
  //   text: 'Place Holders',
  //   sort: false
  // },
  {
    dataField: 'isActive',
    text: 'Status',
    sort: false,
    formatter: (row) => {
      console.log('row', row)
      return <Badge
        className={`badge bg-pill font-size-12 bg-soft-${row? "success" : "danger"}`}
        color={row ? "success" : "danger"}
      >
        {row ? "Active" : 'Suspended'}
      </Badge>
    }
  }
  // ,
  // {
  //     dataField: "createAt",
  //     text: "Date",
  //     formatter: (cellContent, row) => {
      
  //       return (
  //         <div
  //         >
  //           {moment(row.createAt).format('MMM DD, YYYY | hh:mm a')}
  //         </div>
  //     )},
  //     sort: false
  // }
];