import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// action
import { registerUser, apiError, registerUserFailed } from "../../store/actions"

// Redux
import { connect } from "react-redux"
import { Link } from "react-router-dom"

// import images
import logo from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import logoActive from "../../assets/images/logo-active.png";
const AdminRegister = props => {
  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    
    // props.registerUser(values)
  }

  useEffect(() => {
    props.apiError("")
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  

  return (
    <React.Fragment>
      {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="mdi mdi-home-variant h2"></i>
        </Link>
      </div> */}
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <Link to="/" className="mb-5 d-block auth-logo">
                  <img src={logoActive} alt="" height="50" className="logo logo-dark" />
                  {/* <img src={logolight} alt="" height="22" className="logo logo-light" /> */}
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col md={8} lg={6} xl={5}>
              
                <Card>
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Admin Registration</h5>
                      <p className="text-muted">Enter your details to log in</p>
                    </div>
                    <div className="p-2 mt-2">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={(e, v) => {
                          handleValidSubmit(e, v)
                        }}
                      >
                        {props.user && props.user ? (
                          <Alert color="success">
                            Register User Successfully
                          </Alert>
                        ) : null}

                        {props.registrationError &&
                          props.registrationError ? (
                            <Alert color="danger">
                              {props.registrationError}
                            </Alert>
                          ) : null}
                        <Row>
                          <Col lg={6}>
                            <div className="mb-3">
                              <AvField
                                name="firstname"
                                label="First Name"
                                type="text"
                                required
                                placeholder="Enter first name"
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <AvField
                                name="lastname"
                                label="Last Name"
                                type="text"
                                required
                                placeholder="Enter last name"
                              />
                            </div>
                          </Col>
                        </Row>
                        <div className="mb-3">
                          <AvField
                            id="designation"
                            name="designation"
                            label="Designation"
                            className="form-control"
                            placeholder="Enter designation"
                            type="text"
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="Email Address"
                            type="email"
                            required
                            placeholder="Enter email address"
                          />
                        </div>
                        

                        <div className="mt-3 text-end">
                          <button
                            className="btn btn-primary w-sm waves-effect waves-light"
                            type="submit"
                          >
                            Register
                          </button>
                        </div>
                      </AvForm>

                    </div>
                    </CardBody>
                </Card>
            </Col>
          </Row>
          </Container>
      </div>
    </React.Fragment>
  )
}

AdminRegister.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(AdminRegister)
