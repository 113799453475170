import React from "react"
import { Redirect } from "react-router-dom"


// Dashboard
import Dashboard from "../pages/Dashboard/index"

import Clients from "../pages/Clients"

//Contacts
import ContactsGrid from "../pages/Contacts/contacts-grid"
// import ContactsList from "../pages/Contacts/ContactList/contacts-list"
import ContactsProfile from "../pages/Contacts/ContactsProfile/contacts-profile"

// admin settings
import ContactsList from "../pages/AdminSettings/AdminUsersList/admin-setting-list"
import ManageAdmin from "../pages/AdminSettings/ManageAdmin"
import AdminRegister from "../pages/Authentication/AdminRegister"
// roles
import RoleList from "../pages/Roles/RolesList/roles-list"
import ManageRole from "../pages/Roles/manage-role"

//Pages
// import PagesStarter from "../pages/Utility/pages-starter"
// import PagesMaintenance from "../pages/Utility/pages-maintenance"
// import PagesComingsoon from "../pages/Utility/pages-comingsoon"
// import PagesTimeline from "../pages/Utility/pages-timeline"
// import PagesFaqs from "../pages/Utility/pages-faqs"
// // import PagesPricing from "../pages/Utility/pages-pricing"
// import Pages404 from "../pages/Utility/pages-404"
// import Pages500 from "../pages/Utility/pages-500"

// pricing plan 
import PagesPricing from "../pages/Authentication/PagePricing"
// registration success
// import RegistrationSuccess from "../pages/Utility/pages-successfully"
// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Register1 from "../pages/AuthenticationInner/Register"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"

// Profile
import UserProfile from "../pages/Authentication/user-profile"
import ResetPassword from "../pages/Authentication/ResetPassword"
import VerifyUser from "../pages/Authentication/VerifyUser"
import TermCondition from "../pages/Authentication/TermAndCondition"
import Plan from "../pages/Authentication/Plan"
import RegistrationSuccessful from "../pages/Utility/register-success"
import ChangePassword from "../pages/Authentication/ChangePassword"
import CreatePassword from "../pages/Authentication/CreatePassword";
import ResetUserPassword from "../pages/Authentication/Reset-User-Password";



const userRoutes = [
  // { path: "/dashboard", component: Dashboard },
  { path: "/client/dashboard/:id", component: Dashboard },
 

  //Client List
  { path: "/client-list", component: Clients },

  //Admin settings
  { path: "/admin-users", component: ContactsList },
  { path: "/admin/user/:id", component: ManageAdmin },
  { path: "/admin/user", component: ManageAdmin },

  //Roles
  { path: "/manage-role", component: ManageRole },
  // { path: "/roles", component: RoleList },
  

  

  // Contacts
  { path: "/contacts-grid", component: ContactsGrid },
  { path: "/contacts-list", component: ContactsList },
  { path: "/contacts-profile", component: ContactsProfile },

  //Utility
  // { path: "/pages-starter", component: PagesStarter },
  // { path: "/pages-timeline", component: PagesTimeline },
  // { path: "/pages-faqs", component: PagesFaqs },
  // { path: "/pages-pricing", component: PagesPricing },

  
  
  
    // reset user password
    
    { path: "/reset-user-password", component: ResetUserPassword },
    // //profile
    { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/client-list" /> },
]

const authRoutes = [

  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  
  // { path: "/pages-maintenance", component: PagesMaintenance },
  // { path: "/pages-comingsoon", component: PagesComingsoon },
  // { path: "/pages-404", component: Pages404 },
  // { path: "/pages-500", component: Pages500 },
  
  // registration
   { path: "/admin/registration", component: AdminRegister },
   { path: "/registration-success", component: RegistrationSuccessful},
  // { path: "/registration-failed", component: RegistrationFailed},
  // Authentication Inner
  { path: "/pages-login", component: Login1 },
  { path: "/pages-register", component: Register1 },
  { path: "/page-recoverpw", component: Recoverpw },
  { path: "/auth-lock-screen", component: LockScreen },
  // reset password
  { path: "/reset-password", component: ResetPassword},
  // reset password
  { path: "/changePassword/:token", component: ChangePassword},
   // reset password
   { path: "/createSecondaryUserPassword/:token", component: CreatePassword},
  // verify user
  {path:"/activateBusinessPartnerUsers/:token", component: VerifyUser},
  // verify user
  {path:"/termcondition", component: TermCondition},
  // plan
  {path:"/plans", component: Plan},
  // pricing plan
  {path: "/plan", component: PagesPricing}
]

const frameRoutes = [
  { path: "/client/viewOnly/:id", component: Dashboard },
]

export { userRoutes, authRoutes,frameRoutes }