import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import { Row, Col, Alert, Container, CardBody, Card, Spinner } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// action
import { userForgetPassword } from "../../store/actions"
import JwtService from '../../helpers/jwtService'
import { POST_FAKE_PASSWORD_FORGET } from '../../helpers/url_helper'
// import images
import logo from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import logoActive from "../../assets/images/logo-active.png"

const ResetUserPassword = props => {
    const [loading, setLoading] = useState(false);
    const [visible, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({variant: "", message: ""});
    useEffect(() => {
        document.body.className = "authentication-bg";
        // remove classname when component will unmount
        return function cleanup() {
            document.body.className = "";
        };
    });
    

    function handleValidSubmit(event, values) {
        setLoading(true);
        JwtService.post(POST_FAKE_PASSWORD_FORGET, values).then(response => {
            if(response.codes == 200) {
                setAlert(true);
                setLoading(false);
                setAlertMessage({
                    variant: "success",
                    message: response.message
                })
                setTimeout(() => {
                    props.history.push("/logout")
                }, 2000)
              }else {
                setAlert(true);
                
                setLoading(false);
                setAlertMessage({
                    variant: "success",
                    message: response.message
                })
              }
        }).catch(err => {

        })
    }

    return (
        <React.Fragment>
            {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="mdi mdi-home-variant h2"></i>
        </Link>
      </div> */}

            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="" breadcrumbItem="Reset Password" />
                    <Card>

                        <CardBody className="p-4">
                            <Row>
                                <Col xs={12} md={8} lg={5}>
                                    <div className="p-2 mt-4">
                                        <div className="alert alert-success text-center mb-4" role="alert">
                                            Enter your Email and instructions will be sent to you!
                                        </div>
                                        <Row className="justify-content-md-center">
                                            <Col lg={12}>
                                                <Alert color={alertMessage.variant} isOpen={visible} toggle={() => setAlert(false)} fade={false}>
                                                    {alertMessage.message}
                                                </Alert>
                                            </Col>
                                        </Row>

                                        <AvForm
                                            className="form-horizontal"
                                            onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                                        >
                                            <div className="mb-3">
                                                <AvField
                                                    name="email"
                                                    label="Email"
                                                    className="form-control"
                                                    placeholder="Enter email"
                                                    type="email"
                                                    required
                                                />
                                            </div>
                                            <Row className="row mb-0">
                                                <Col className="col-12 text-left">
                                                    <button
                                                        className="btn btn-primary w-md waves-effect waves-light"
                                                        type="submit"
                                                        disabled={loading}
                                                    >
                                                        {loading ? <span ><Spinner className="spinner-border-sm mr-5" color="white" /> Loading...</span> : "Reset"}
                                                    </button>
                                                </Col>
                                            </Row>
                                            {/* <div className="mt-4 text-center">
                          <p className="mb-0">Remember It ? <Link to="/login" className="fw-medium text-primary"> Signin </Link></p>
                        </div> */}
                                        </AvForm>
                                    </div>
                                </Col>
                            </Row>

                        </CardBody>
                    </Card>


                </Container>
            </div>
        </React.Fragment>
    )
}

ResetUserPassword.propTypes = {
    forgetError: PropTypes.any,
    forgetSuccessMsg: PropTypes.any,
    history: PropTypes.object,
    userForgetPassword: PropTypes.func
}

const mapStatetoProps = state => {
    const { forgetError, forgetSuccessMsg } = state.ForgetPassword
    return { forgetError, forgetSuccessMsg }
}

export default withRouter(
    connect(mapStatetoProps, { userForgetPassword })(ResetUserPassword)
)
