import React, {useEffect, useState} from "react"
import { Container, Row, Col, Alert } from "reactstrap"
import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import logoActive from "../../assets/images/logo-active.png";
//Import Pricing Cards
import CardPricing from './Card-Pricing';
import JwtService from "../../helpers/jwtService";
import { BUY_PLAN, GET_PLAN } from "../../helpers/url_helper"

const PagesPricing = (props) => {

    const [plans, setPlans] = useState([]);
    const [visible, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({variant: "", message: ""});
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setLoader(true);
        JwtService.get(GET_PLAN).then(res => {
            
            let updatePlan = res.map(plan => {
                return {
                    ...plan,
                    features: [
                        { title: "Conference plans", value: "" },
                        { title: "Free Lunch And Coffee", value: "" },
                        { title: "Certificate", value: "No" },
                        { title: "Easy Access", value: "No" },
                        { title: "Free Contacts ", value: "No" },
                    ],
                }
            })
            setPlans(updatePlan);
            setLoader(false);
        });
    }, [])
    
    const buyPlan = (id) => {
        
        let userId = parseInt(localStorage.getItem('userId'));
        JwtService.post(BUY_PLAN, {planId: id, businessId: userId}).then(res => {
           
            if(res.code == 200) {
                setAlertMessage(res.message);
                setAlert(true);
                localStorage.removeItem('userId')
                setTimeout(() => {
                    props.history.push("/login");
                }, 2000)
            }
            
        })
    }

    const pricings = [
        {
            id: 1,
            title: "Starter",
            description: "Starter plans",
            icon: "edit",
            price: "19",
            duration: "Per month",
            link: "",
            features: [
                { title: "Conference plans", value: "" },
                { title: "Free Lunch And Coffee", value: "" },
                { title: "Certificate", value: "No" },
                { title: "Easy Access", value: "No" },
                { title: "Free Contacts ", value: "No" },
            ],
        },
        {
            id: 2,
            title: "Professional",
            description: "Professional plans",
            icon: "medal",
            price: "29",
            duration: "Per month",
            link: "",
            features: [
                { title: "Conference plans", value: "" },
                { title: "Free Lunch And Coffee", value: "" },
                { title: "Certificate", value: "No" },
                { title: "Easy Access", value: "No" },
                { title: "Free Contacts ", value: "No" },
            ],
        },
        {
            id: 3,
            title: "Unlimited",
            description: "Unlimited plans",
            icon: "layer-group",
            price: "49",
            duration: "Per month",
            link: "",
            features: [
                { title: "Conference plans", value: "" },
                { title: "Free Lunch And Coffee", value: "" },
                { title: "Certificate", value: "No" },
                { title: "Easy Access", value: "No" },
                { title: "Free Contacts ", value: "No" },
            ],
        },
    ]
    
    return (
        <React.Fragment>
            <div className="page-content py-5">
                <Container fluid>
                    {/* Render Breadcrumbs */}

                    <Row className="justify-content-center">
                        <Col lg={5}>
                            <div className="home-wrapper">
                                <Link to="/" className="mb-3 d-block auth-logo">
                                    <img src={logoActive} alt="" height="50" className="logo logo-dark m-auto" />
                                </Link>
                            </div>
                            <div className="text-center mb-5">
                                <h4>Choose your Pricing plan</h4>
                                <p className="text-muted mb-4">
                                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo veritatis
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col lg={6}>
                            <Alert color={alertMessage.variant} isOpen={visible} toggle={() => setAlert(false)} fade={false}>
                                {alertMessage.message}
                            </Alert>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col lg={9}>
                            <Row className="justify-content-md-center">
                                {plans.map((pricing, key) => (
                                    <CardPricing pricing={pricing} key={"_pricing_" + key} buyPlan={buyPlan} setAlertMessage={setAlertMessage} setAlert={setAlert}/>
                                ))}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default PagesPricing
