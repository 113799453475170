import product1 from "../../assets/images/product/img-1.png"
import product2 from "../../assets/images/product/img-2.png"
import product3 from "../../assets/images/product/img-3.png"
import product4 from "../../assets/images/product/img-4.png"
import product5 from "../../assets/images/product/img-5.png"
import product6 from "../../assets/images/product/img-6.png"


import avatar2 from "../../assets/images/users/avatar-2.jpg"
import avatar3 from "../../assets/images/users/avatar-3.jpg"
import avatar4 from "../../assets/images/users/avatar-4.jpg"
import avatar5 from "../../assets/images/users/avatar-5.jpg"
import avatar6 from "../../assets/images/users/avatar-6.jpg"
import avatar8 from "../../assets/images/users/avatar-8.jpg"

const productsData = [
  {
    id: 1,
    image: product1,
    name: "Nike N012 Shoes",
    link: "#",
    category: "Nike",
    currentcolor: "Gray",
    islable: true,
    lable: "Trending",
    oldPrice: 280,
    newPrice: 405,
    isOffer: false,
    offer: 0,
    reviews: 234,
    colors: ['dark', 'light', 'primary'],
    shortspecifications: ['High Quality', 'Leather', 'All Sizes available', '4 Different Color'],
    decription : 'If several languages coalesce, the grammar of the resulting language is more simple and regular',
    shortservices: [
      { icon: "exchange", value: "10 Days Replacement" },
      { icon: "bill", value: "Cash on Delivery available" },
    ],
    extraimgs :[product2,product3,product4],
    specification: [
      { type: "Category", value: "Shoes" },
      { type: "Brand", value: "Nike" },
      { type: "Color", value: "Gray" },
      { type: "Quality", value: "High" },
      { type: "Material", value: "Leather" },
    ],
    colorOptions: [
      { image: product1, color: "Red" },
      { image: product2, color: "Dark" },
      { image: product3, color: "Purple" },
    ],
  },
  {
    id: 2,
    image: product2,
    name: "Adidas Running Shoes",
    link: "#",
    category: "Nike",
    currentcolor: "Black",
    islable: false,
    lable: "",
    oldPrice: 250,
    newPrice: 240,
    isOffer: true,
    offer: 20,
    colors: ['danger', 'dark', 'light'],
    reviews: 100,
    shortspecifications: ['High Quality', 'Leather', 'All Sizes available', '4 Different Color'],
    decription : 'If several languages coalesce, the grammar of the resulting language is more simple and regular',
    shortservices: [
      { icon: "exchange", value: "10 Days Replacement" },
      { icon: "bill", value: "Cash on Delivery available" },
    ],
    extraimgs :[product1,product3,product4],
    specification: [
      { type: "Category", value: "Shoes" },
      { type: "Brand", value: "Nike" },
      { type: "Color", value: "Black" },
      { type: "Quality", value: "High" },
      { type: "Material", value: "Leather" },
    ],
    colorOptions: [
      { image: product2, color: "Red" },
      { image: product4, color: "Dark" },
      { image: product3, color: "Purple" },
    ],
  },
  {
    id: 3,
    image: product3,
    name: "Puma P103 Shoes",
    link: "#",
    category: "Nike",
    currentcolor: "Purple",
    islable: false,
    lable: "",
    oldPrice: 260,
    newPrice: 250,
    isOffer: false,
    offer: 0,
    colors: ['purple', 'light', 'dark'],
    reviews: 200,
    shortspecifications: ['High Quality', 'Leather', 'All Sizes available', '4 Different Color'],
    decription : 'If several languages coalesce, the grammar of the resulting language is more simple and regular',
    shortservices: [
      { icon: "exchange", value: "10 Days Replacement" },
      { icon: "bill", value: "Cash on Delivery available" },
    ],
    extraimgs :[product1,product2,product4],
    specification: [
      { type: "Category", value: "Shoes" },
      { type: "Brand", value: "Nike" },
      { type: "Color", value: "Black" },
      { type: "Quality", value: "High" },
      { type: "Material", value: "Leather" },
    ],
    colorOptions: [
      { image: product3, color: "Red" },
      { image: product4, color: "Dark" },
      { image: product1, color: "Purple" },
    ],
  },
  {
    id: 4,
    image: product4,
    name: "Sports S120 Shoes",
    link: "#",
    category: "Nike",
    currentcolor: "Cyan",
    islable: false,
    lable: "",
    oldPrice: 240,
    newPrice: 230,
    isOffer: false,
    offer: 0,
    colors: ['info', 'success'],
    reviews: 150,
    shortspecifications: ['High Quality', 'Leather', 'All Sizes available', '4 Different Color'],
    decription : 'If several languages coalesce, the grammar of the resulting language is more simple and regular',
    shortservices: [
      { icon: "exchange", value: "10 Days Replacement" },
      { icon: "bill", value: "Cash on Delivery available" },
    ],
    extraimgs :[product1,product2,product3],
    specification: [
      { type: "Category", value: "Shoes" },
      { type: "Brand", value: "Nike" },
      { type: "Color", value: "Cyan" },
      { type: "Quality", value: "High" },
      { type: "Material", value: "Leather" },
    ],
    colorOptions: [
      { image: product4, color: "Red" },
      { image: product1, color: "Dark" },
      { image: product2, color: "Purple" },
    ],
  },
  {
    id: 5,
    image: product5,
    name: "Adidas AB23 Shoes",
    link: "#",
    category: "Nike",
    currentcolor: "Blue",
    islable: false,
    lable: "",
    oldPrice: 240,
    newPrice: 250,
    isOffer: false,
    offer: 0,
    colors: ['dark', 'light', 'primary'],
    reviews: 170,
    shortspecifications: ['High Quality', 'Leather', 'All Sizes available', '4 Different Color'],
    decription : 'If several languages coalesce, the grammar of the resulting language is more simple and regular',
    shortservices: [
      { icon: "exchange", value: "10 Days Replacement" },
      { icon: "bill", value: "Cash on Delivery available" },
    ],
    extraimgs :[product5,product2,product3],
    specification: [
      { type: "Category", value: "Shoes" },
      { type: "Brand", value: "Nike" },
      { type: "Color", value: "Blue" },
      { type: "Quality", value: "High" },
      { type: "Material", value: "Leather" },
    ],
    colorOptions: [
      { image: product5, color: "Red" },
      { image: product2, color: "Dark" },
      { image: product3, color: "Purple" },
    ],
  },
  {
    id: 6,
    image: product6,
    name: "Nike N012 Shoes",
    link: "#",
    category: "Nike",
    currentcolor: "Gray",
    islable: false,
    lable: "",
    oldPrice: 270,
    newPrice: 260,
    isOffer: true,
    offer: 20,
    colors: ['dark', 'light'],
    reviews: 80,
    shortspecifications: ['High Quality', 'Leather', 'All Sizes available', '4 Different Color'],
    decription : 'If several languages coalesce, the grammar of the resulting language is more simple and regular',
    shortservices: [
      { icon: "exchange", value: "10 Days Replacement" },
      { icon: "bill", value: "Cash on Delivery available" },
    ],
    extraimgs :[product6,product4,product3],
    specification: [
      { type: "Category", value: "Shoes" },
      { type: "Brand", value: "Nike" },
      { type: "Color", value: "Gray" },
      { type: "Quality", value: "High" },
      { type: "Material", value: "Leather" },
    ],
    colorOptions: [
      { image: product6, color: "Red" },
      { image: product4, color: "Dark" },
      { image: product3, color: "Purple" },
    ],
  },
]

const comments = [
  {
    id: 1,
    name: "Samuel",
    description:
      "It will be as simple as in fact, it will be Occidental. It will seem like simplified",
    date: "12 July, 2020",
    review: "4.1"
  },
  {
    id: 2,
    name: "Joseph",
    description:
      "Sed ut perspiciatis unde omnis iste natus error sit",
    date: "06 July, 2020",
    review: "4.0",
  },
  {
    id: 3,
    name: "Paul",
    description:
      "Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet",
    date: "26 June, 2020",
    review: "4.2",
  },
]

const discountData = [
  { label: "Less than 10%", value: 0 },
  { label: "10% or more", value: 10 },
  { label: "20% or more", value: 20 },
  { label: "30% or more", value: 30 },
  { label: "40% or more", value: 40 },
  { label: "50% or more", value: 50 },
]

const colorData = [
  { label: "Black", value: 'dark' },
  { label: "White", value: 'light' },
  { label: "Gray", value: 'secondary' },
  { label: "Blue", value: 'primary' },
  { label: "Green", value: 'success' },
  { label: "Red", value: 'danger' },
  { label: "Yellow", value: 'warning' },
  { label: "Purple", value: 'purple' },
]

const orders = [
  {
    id: 1,
    orderId: "#MN0132",
    firstName: "10 Jul, 2020",
    billingName: "Melvin Martin",
    total: "$142",
    badgeclass: "success",
    paymentStatus: "Paid",
    Date: "05 Jul, 2020"
  },
  {
    id: 2,
    orderId: "#MN0131",
    firstName: "09 Jul, 2020",
    billingName: "Roy Michael",
    total: "$130",
    badgeclass: "success",
    paymentStatus: "Paid",
    Date: "05 Jul, 2020"
  },
  {
    id: 3,
    orderId: "#MN0130",
    firstName: "08 Jul, 2020",
    billingName: "Shelby Wolf",
    total: "$123",
    badgeclass: "warning",
    paymentStatus: "unpaid",
    Date: "05 Jul, 2020"
  },
  {
    id: 4,
    orderId: "#MN0129",
    firstName: "07 Jul, 2020",
    billingName: "James Riddick",
    total: "$173",
    badgeclass: "success",
    paymentStatus: "Paid",
    Date: "05 Jul, 2020"
  },
  {
    id: 5,
    orderId: "#MN0128",
    firstName: "07 Jul, 2020",
    billingName: "George Kwan",
    total: "$160",
    badgeclass: "danger",
    paymentStatus: "Chargeback",
    Date: "05 Jul, 2020"
  },
  {
    id: 6,
    orderId: "#MN0127",
    firstName: "06 Jul, 2020",
    billingName: "Kevin Patterson",
    total: "$165",
    badgeclass: "success",
    paymentStatus: "Paid",
    Date: "05 Jul, 2020"
  },
  {
    id: 7,
    orderId: "#MN0126",
    firstName: "05 Jul, 2020",
    billingName: "Danny Orr",
    total: "$161",
    badgeclass: "success",
    paymentStatus: "Paid",
    Date: "05 Jul, 2020"
  },
  {
    id: 8,
    orderId: "#MN0125",
    firstName: "04 Jul, 2020",
    billingName: "Sylvia Garcia",
    total: "$153",
    badgeclass: "warning",
    paymentStatus: "unpaid",
    Date: "04 Jul, 2020"
  },
  {
    id:9,
    orderId: "#MN0124",
    firstName: "04 Jul, 2020",
    billingName: "Charles Denney",
    total: "$152",
    badgeclass: "success",
    paymentStatus: "Paid",
    Date: "04 Jul, 2020"
  },
  {
    id: 10,
    orderId: "#MN0123",
    firstName: "03 Jul, 2020",
    billingName: "Lisa Farrell",
    total: "$167",
    badgeclass: "success",
    paymentStatus: "Paid",
    Date: "03 Jul, 2020"
  },
  {
    id: 11,
    orderId: "#MN0122",
    firstName: "02 Jul, 2020",
    billingName: "Connie Franco",
    total: "$163",
    badgeclass: "success",
    paymentStatus: "Paid",
    Date: "02 Jul, 2020"
  },
  {
    id: 12,
    orderId: "#MN0121",
    firstName: "02 Jul, 2020",
    billingName: "Lara Casillas",
    total: "$171",
    badgeclass: "success",
    paymentStatus: "Paid",
    Date: "02 Jul, 2020"
  },
]

const cartData = {
  products: [
    {
      id: 1,
      img: "img1",
      name: "Half sleeve T-shirt",
      color: "Maroon",
      price: "450",
      data_attr: 2,
      total: 900,
    },
    {
      id: 2,
      img: "img2",
      name: "Light blue T-shirt",
      color: "Light blue",
      price: "225",
      data_attr: 6,
      total: 225,
    },
    {
      id: 3,
      img: "img3",
      name: "Black Color T-shirt",
      color: "Black",
      price: "152",
      data_attr: 2,
      total: 304,
    },
    {
      id: 4,
      img: "img4",
      name: "Hoodie (Blue)",
      color: "Blue",
      price: "145",
      data_attr: 2,
      total: 290,
    },
    {
      id: 5,
      img: "img5",
      name: "Half sleeve T-Shirt",
      color: "Light orange",
      price: "138",
      data_attr: 8,
      total: 138,
    },
    {
      id: 6,
      img: "img6",
      name: "Green color T-shirt",
      color: "Green",
      price: "152",
      data_attr: 2,
      total: 304,
    },
  ],
  orderSummary: {
    grandTotal: "$ 1,857",
    discount: "$ 157",
    shippingCharge: "$ 25",
    estimatedTax: "$ 19.22",
    total: "$ 1744.22",
  },
}

const customerData = [
  {
    id: "1",
    customerId: "#MN0123",
    img: avatar2,
    customer: "William Shipp",
    email: "WilliamShipp@jourrapide.com",
    joiningDate: "14 Apr, 2020",
    badgeclass: "success",
    status: "Active",
  },
  {
    id: "2",
    customerId: "#MN0122",
    img: "null",
    customer: "Joe Hardy",
    email: "JoeHardy@dayrep.com",
    joiningDate: "14 Apr, 2020",
    badgeclass: "success",
    status: "Active",
  },
  {
    id: "3",
    customerId: "#MN0121",
    img: avatar3,
    customer: "Thomas Hankins",
    email: "ThomasHankins@dayrep.com",
    joiningDate: "13 Apr, 2020",
    badgeclass: "success",
    status: "Active",
  },
  {
    id: "4",
    customerId: "#MN0120",
    img: avatar4,
    customer: "Mary Frazier",
    email: "MaryFrazier@armyspy.com",
    joiningDate: "12 Apr, 2020",
    badgeclass: "danger",
    status: "Deactive",
  },
  {
    id: "5",
    customerId: "#MN0119",
    img: "null",
    customer: "Sam Perry",
    email: "SamPerry@rhyta.com",
    joiningDate: "12 Apr, 2020",
    badgeclass: "success",
    status: "Active",
  },
  {
    id: "6",
    customerId: "#MN0118",
    img: avatar5,
    customer: "Muriel Myers",
    email: "MurielMyers@rhyta.com",
    joiningDate: "12 Apr, 2020",
    badgeclass: "danger",
    status: "Deactive",
  },
  {
    id: "7",
    customerId: "#MN0117",
    img: "null",
    customer: "Jessie Jacobs",
    email: "JessieJacobs@teleworm.us",
    joiningDate: "09 Apr, 2020",
    badgeclass: "success",
    status: "Active",
  },
  {
    id: "8",
    customerId: "#MN0116",
    img: avatar6,
    customer: "Edward King",
    email: "EdwardKing@teleworm.us",
    joiningDate: "08 Apr, 2020",
    badgeclass: "success",
    status: "Active",
  },
  {
    id: "9",
    customerId: "#MN0115",
    img: avatar6,
    customer: "Stacy Webster",
    email: "StacyWebster@armyspy.com",
    joiningDate: "07 Apr, 2020",
    badgeclass: "danger",
    status: "Deactive",
  },
  {
    id: "10",
    customerId: "#MN0114",
    img: "null",
    customer: "Amy McDonald",
    email: "AmyMcDonald@jourrapide.com",
    joiningDate: "05 Apr, 2020",
    badgeclass: "success",
    status: "Active",
  },
  {
    id: "11",
    customerId: "#MN0113",
    img: "null",
    customer: "Terry Brown",
    email: "TerryBrown@dayrep.com",
    joiningDate: "02 Apr, 2020",
    badgeclass: "success",
    status: "Active",
  },
  {
    id: "12",
    customerId: "#MN0112",
    img: avatar8,
    customer: "Crissy Holland",
    email: "CrissyHolland@armyspy.com",
    joiningDate: "23 Mar, 2020",
    badgeclass: "danger",
    status: "Deactive",
  },
]

const shops = [
  {
    id: 1,
    color: "primary",
    name: "Brendle's",
    subtitle:"Clifton Taylor",
    product: 112,
    balance: "13,575",
    profileLink: "#",
  },
  {
    id: 2,
    color: "warning",
    name: "Tech Hifi",
    subtitle:"Karl Early",
    product: 104,
    balance: "11,145",
    profileLink: "#",
  },
  {
    id: 3,
    color: "danger",
    name: "Lafayette",
    subtitle:"Marion Glaze",
    product: 126,
    balance: "12,356",
    profileLink: "#",
  },
  {
    id: 4,
    color: "success",
    name: "Packer",
    subtitle : "Brent Johnson",
    product: 102,
    balance: "11,228",
    profileLink: "#",
  },
  {
    id: 5,
    color: "info",
    name: "Nedick's",
    subtitle : "Kimberly Martinez",
    product: 96,
    balance: "9,235",
    profileLink: "#",
  },
  {
    id: 6,
    color: "dark",
    name: "Hudson's",
    subtitle : "Sarah Stewart",
    product: 120,
    balance: "14,794",
    profileLink: "#",
  },
  {
    id: 7,
    color: "dark",
    name: "Tech Hifi",
    subtitle : "Lauren Doyle",
    product: 104,
    balance: "11,145",
    profileLink: "#",
  },
  {
    id: 8,
    color: "primary",
    name: "Brendle's",
    subtitle : "Elaina Torres",
    product: 112,
    balance: "13,575",
    profileLink: "#",
  },
  {
    id: 9,
    color: "success",
    name: "Lafayette",
    subtitle : "Willie Farber",
    product: 120,
    balance: "12,356",
    profileLink: "#",
  },
]

export {
  productsData,
  comments,
  discountData,
  colorData,
  orders,
  shops,
  customerData,
  cartData,
}
