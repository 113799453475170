import React, { useState } from "react"
import { Link, useParams } from "react-router-dom"
import {
  Card,
  Col,
  Container,
  Form,
  Media,
  Input,
  Label,
  Row,
  Alert,
  Collapse
} from "reactstrap"
import Select from "react-select"
import { connect } from "react-redux"
import Dropzone from "react-dropzone"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { registerAdmin, apiError, registerAdminUserFailed } from "../../store/actions"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const ManageAdmin = (props) => {
  const params = useParams();
  
  const [role, setRole] = useState(null);
  const [status, setStatus] = useState(null);
  const [selectedFiles, setselectedFiles] = useState([])
  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => setIsOpen(!isOpen);

  const [isOpenAddproduct, setIsOpenAddproduct] = useState(false);

  const toggleAddproduct = () => setIsOpenAddproduct(!isOpenAddproduct);

  const [isOpenMetadata, setIsOpenMetadata] = useState(false);

  const toggleMetadata = () => setIsOpenMetadata(!isOpenMetadata);

  const options = [
    { value: "AK", label: "Alaska" },
    { value: "HI", label: "Hawaii" },
    { value: "CA", label: "California" },
    { value: "NV", label: "Nevada" },
    { value: "OR", label: "Oregon" },
    { value: "WA", label: "Washington" }
  ]

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size)
      })
    )

    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const handleValidSubmit = (event, values) => {
    
    

    if(role) {
      values['role'] = role;
    }
    if(status) {
      values['status'] = status;
    }
    
    props.registerAdmin(values)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs  breadcrumbItem="Add User" />
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleValidSubmit(e, v)
            }}
          >
          <Row>
            <Col lg="12">
              <div id="addproduct-accordion" className="custom-accordion">
                <Card>
                  <div className="p-4 border-top">
                     
                        <Row>
                            <Col lg="4">
                                <Row>
                                    <Col lg="12">
                                        <div className="mb-3">
                                          <AvField
                                            id="productname"
                                            name="username"
                                            label="User Name"
                                            type="text"
                                            required
                                            placeholder="Enter user name"
                                          />
                                        </div>
                                    </Col>
                                    <Col lg="12">
                                        <div className="mb-3">
                                          <AvField
                                            id="Email"
                                            name="email"
                                            label="Email"
                                            type="email"
                                            required
                                            placeholder="Email"
                                          />
                                        </div>
                                    </Col>
                                    <Col md="12">
                                        <div className="mb-3">
                                          <Label className="control-label">Role</Label>
                                          <Select
                                            options={[{label: "option", value: "option"}, {label: "option1", value: "Option1"}]}
                                            values={role}
                                            onChange={(data)=> setRole(data)}
                                          />
                                        </div>
                                    </Col>
                                    <Col md="12">
                                        <div className="mb-3">
                                          <Label className="control-label">Status</Label>
                                          <Select
                                            options={[{label: "AK", value: "AK"}, {label: "HI", value: "HI"}]}
                                            values={status}
                                            onChange={(data)=> setStatus(data)}
                                          />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                    <Col>
                    <div className="mt-3">
                        {props.user && props.user ? (
                          <Alert color="success">
                            {props?.user?.message}
                          </Alert>
                        ) : null}

                        {props.registrationError &&
                          props.registrationError ? (
                            <Alert color="danger">
                              {props.registrationError}
                            </Alert>
                          ) : null}
                      </div>
                    </Col>
                  </Row>
                  </div>
                  
                </Card>
                
              </div>
            </Col>
          </Row>
          <Row className="row mb-4">
            <div className="col text-end">
              <Link to="#" className="btn btn-danger"> <i className="uil uil-times me-1"></i> Cancel </Link>{" "}
              <button type="submit" className="btn btn-primary"> <i className="uil uil-file-alt me-1"></i> Save </button>
            </div>
          </Row>
          </AvForm>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({adminSetting}) => {
  return ({
    ...adminSetting
  })
}

const mapDispatchToProps = dispatch => ({
  onGetAdminUsers: () => () => {},
})

export default connect(mapStateToProps, {
  registerAdmin,
  apiError,
  registerAdminUserFailed
})(ManageAdmin)
