import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  Col,
  Container,
  Form,
  Media,
  Input,
  Label,
  Row,
  Collapse
} from "reactstrap"
import Select from "react-select"
import Dropzone from "react-dropzone"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const ManageRole = () => {
  const [selectedFiles, setselectedFiles] = useState([])
  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => setIsOpen(!isOpen);

  const [isOpenAddproduct, setIsOpenAddproduct] = useState(false);

  const toggleAddproduct = () => setIsOpenAddproduct(!isOpenAddproduct);

  const [isOpenMetadata, setIsOpenMetadata] = useState(false);

  const toggleMetadata = () => setIsOpenMetadata(!isOpenMetadata);

  const options = [
    { value: "AK", label: "Alaska" },
    { value: "HI", label: "Hawaii" },
    { value: "CA", label: "California" },
    { value: "NV", label: "Nevada" },
    { value: "OR", label: "Oregon" },
    { value: "WA", label: "Washington" }
  ]

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size)
      })
    )

    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs  breadcrumbItem="Create Role" />

          <Row>
            <Col lg="12">
              <div id="addproduct-accordion" className="custom-accordion">
                <Card>
                  <div className="p-4 border-top">
                    <Form>
                        <Row>
                            <Col lg="4">
                                <Row>
                                    <Col lg="12">
                                        <div className="mb-3">
                                            <Label htmlFor="productname">Role Name</Label>
                                            <Input
                                                id="rolename"
                                                name="rolename"
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="12">
                                        <div className="mb-3">
                                            <Label htmlFor="manufacturername">
                                                Email
                                            </Label>
                                            <div className="form-check mb-3">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value=""
                                                    id="viewonly"
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="viewonly"
                                                >
                                                    View Only
                                                </label>
                                            </div>
                                            <div className="form-check mb-3">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value=""
                                                    id="viewdownload"
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="viewdownload"
                                                >
                                                    View and Download
                                                </label>
                                            </div>
                                            <div className="form-check mb-3">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value=""
                                                    id="accessdata"
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="accessdata"
                                                >
                                                    Access Data
                                                </label>
                                            </div>
                                            <div className="form-check mb-3">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value=""
                                                    id="viewdashboard"
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="viewdashboard"
                                                >
                                                    View Dashboard
                                                </label>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                  </div>
                  
                </Card>
                
              </div>
            </Col>
          </Row>
          <Row className="row mb-4">
            <div className="col text-end">
              <Link to="#" className="btn btn-danger"> <i className="uil uil-times me-1"></i> Cancel </Link>{" "}
              <Link to="#" className="btn btn-primary"> <i className="uil uil-file-alt me-1"></i> Save Role </Link>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ManageRole;
