import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"
import { POST_FAKE_LOGIN } from "./url_helper"
import JwtService from "./jwtService"
//pass new generated access token here
const token = accessToken

//apply base url for axios
const API_URL = "https://20f97ea3-5f18-47ed-8d96-d4c3c793d901.mock.pstmn.io"

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
 
  return  fetch((API_URL + ''+ url), {method: 'GET'}).then(response => response.json()).then(data => data);
}

export async function post(url, data, config = {}) {
 
  return  fetch((API_URL + ''+ url), {method: 'POST',body: JSON.stringify(data)}).then(response => response.json()).then(data => data);
  // return await axiosApi.post((API_URL + ''+POST_FAKE_LOGIN), data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
