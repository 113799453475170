import {
  GET_USER_PROFILE,
  GET_USER_PROFILE_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_ADMIN_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  REGISTER_ADMIN,
  REGISTER_ADMIN_USER_SUCCESSFUL,
  REGISTER_ADMIN_USER_FAILED
} from "./actionTypes"

export const registerAdmin = user => {
  return {
    type: REGISTER_ADMIN,
    payload: { user },
  }
}

export const getAdminUsers = () => ({
  type: GET_ADMIN_USERS,
})

export const getUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users,
})

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
})

export const getUserProfile = () => ({
  type: GET_USER_PROFILE,
})

export const getUserProfileSuccess = userProfile => ({
  type: GET_USER_PROFILE_SUCCESS,
  payload: userProfile,
})

export const getUserProfileFail = error => ({
  type: GET_USER_PROFILE_FAIL,
  payload: error,
})

export const registerAdminUserSuccessful = user => {
  return {
    type: REGISTER_ADMIN_USER_SUCCESSFUL,
    payload: user,
  }
}

export const registerAdminUserFailed = user => {
  return {
    type: REGISTER_ADMIN_USER_FAILED,
    payload: user,
  }
}