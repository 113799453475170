import {
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAIL,
  REGISTER_ADMIN,
  REGISTER_ADMIN_USER_SUCCESSFUL,
  REGISTER_ADMIN_USER_FAILED
} from "./actionTypes"

const INIT_STATE = {
  users: [],
  user: null,
  userProfile: {},
  error: {},
  registrationError: null,
  message: null,
  loading: false,
}

const adminSetting = (state = INIT_STATE, action) => {
  switch (action.type) {
    case REGISTER_ADMIN:
      
      return  {
        ...state,
        loading: true,
        registrationError: null,
      }
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
      }

    case GET_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
      }

    case GET_USER_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case REGISTER_ADMIN_USER_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        user: action.payload,
        registrationError: null,
      }
      
    case REGISTER_ADMIN_USER_FAILED:
      
      return {
        ...state,
        user: null,
        loading: false,
        registrationError: action.payload,
      }

    default:
      return state
  }
}

export default adminSetting
