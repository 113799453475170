import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_ADMIN_USERS, GET_USER_PROFILE, REGISTER_ADMIN } from "./actionTypes"
import {
  getUsersSuccess,
  getUsersFail,
  getUserProfileSuccess,
  getUserProfileFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getUsers, getUserProfile, getAdminUsers } from "../../helpers/fakebackend_helper"
import { postJwtAdmin } from "../../helpers/fakebackend_helper"
import { registerAdminUserSuccessful , registerAdminUserFailed} from "./actions"
function* fetchAdminUsers() {
  try {
    const response = yield call(getAdminUsers)
    yield put(getUsersSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchUserProfile() {
  try {
    const response = yield call(getUserProfile)
    yield put(getUserProfileSuccess(response))
  } catch (error) {
    yield put(getUserProfileFail(error))
  }
}

// Is user register successfull then direct plot user in redux.
function* registerAdmin({ payload: { user } }) {
  console.log('user admin', user)
  try {
      const response = yield call(postJwtAdmin, user)
      console.log(response)
      yield put(registerAdminUserSuccessful(response))
    
  } catch (error) {
    yield put(registerAdminUserFailed(error))
  }
}

function* adminSettingSaga() {
  yield takeEvery(GET_ADMIN_USERS, fetchAdminUsers)
  yield takeEvery(GET_USER_PROFILE, fetchUserProfile)
  yield takeEvery(REGISTER_ADMIN, registerAdmin)
}



export default adminSettingSaga
