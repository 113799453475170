import axios from "axios"
import { post, del, get, put } from "./api_helper"
import * as url from "./url_helper"
import JwtService from "./jwtService"
// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Register Method
const postFakeRegister = (data) => post(url.POST_FAKE_REGISTER, data)

// Login Method
const postFakeLogin = data => {
  
  return  JwtService.post(url.POST_FAKE_LOGIN, data)
}

// postForgetPwd
const postFakeForgetPwd = data => JwtService.post(url.POST_FAKE_PASSWORD_FORGET, data)

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data)

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data)

// Register Method
const postJwtRegister = (data) => {
  
  return JwtService.post(url.POST_FAKE_REGISTER, data)
}

// register Admin
const postJwtAdmin = (data) => {
  
  return JwtService.post(url.POST_ADMIN_REGISTER, data)
}
// Login Method
const postJwtLogin = data => {
 
  return post(url.POST_FAKE_JWT_LOGIN, data);
}

// postForgetPwd
const postJwtForgetPwd = data => JwtService.post(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// change password
export const postChangePassword = data => JwtService.post(url.CHANGE_PASSWORD, data)

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data)

// get Products
export const getProducts = () => get(url.GET_PRODUCTS)

// get Product detail
export const getProductDetail = id =>
  get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } })

// get Events
export const getEvents = () => get(url.GET_EVENTS)

// add Events
export const addNewEvent = event => post(url.ADD_NEW_EVENT, event)

// update Event
export const updateEvent = event => put(url.UPDATE_EVENT, event)

// delete Event
export const deleteEvent = event =>
  del(url.DELETE_EVENT, { headers: { event } })

// get Categories
export const getCategories = () => JwtService.get(url.GET_CATEGORIES)

// get chats
export const getChats = () => JwtService.get(url.GET_CHATS)

// get groups
export const getGroups = () => JwtService.get(url.GET_GROUPS)

// get Contacts
export const getContacts = () => JwtService.get(url.GET_CONTACTS)

// get messages
export const getMessages = (roomId = "") =>
JwtService.getWithParams(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } })

// post messages
export const addMessage = message => JwtService.post(url.ADD_MESSAGE, message)

// get orders
export const getOrders = () => {
  return JwtService.get(url.GET_ORDERS)
}


// get cart data
export const getCartData = () => JwtService.get(url.GET_CART_DATA)

// get customers
export const getCustomers = () => JwtService.get(url.GET_CUSTOMERS)

// get shops
export const getShops = () => JwtService.get(url.GET_SHOPS)

// get wallet
export const getWallet = () => JwtService.get(url.GET_WALLET)

// get crypto order
export const getCryptoOrder = () => JwtService.get(url.GET_CRYPTO_ORDERS)

// get invoices
export const getInvoices = () => JwtService.get(url.GET_INVOICES)

// get invoice details
export const getInvoiceDetail = id =>
JwtService.getWithParams(`${url.GET_INVOICE_DETAIL}/${id}`, { params: { id } })

// get project
export const getProjects = () => JwtService.get(url.GET_PROJECTS)

// get project details
export const getProjectsDetails = id =>
JwtService.getWithParams(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } })

// get tasks
export const getTasks = () => JwtService.get(url.GET_TASKS)

// get contacts
export const getUsers = () => JwtService.get(url.GET_USERS)

// get admin setting list
export const getAdminUsers = (params) => JwtService.post(url.GET_ADMIN_USERS, params)
// assign permission to secondary user
export const assignPermissionSecondaryUser = (params) => {
  return JwtService.post(url.ASSIGN_PERMISSION_SECONDARY_USER, params)
}

// get roles
export const getRoles = () => JwtService.get(url.GET_ROLES);

export const getUserProfile = () => JwtService.get(url.GET_USER_PROFILE)

// get clients
export const getClients = (params) => {
  return JwtService.post(url.GET_CLIENTS, params)
}

// get clients
export const getBankList = (params) => {
  return JwtService.get(`${url.BANK_LIST}/${params}`)
}

export const getAccountByBank = (params) => {
  return JwtService.post(url.ACCOUNT_BY_BANK, params)
}

export const getDashboardDetail = (params) => {
  return JwtService.post(url.DASHBOARD_DETAIL, params)
}

export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postFakeLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile,
  postJwtAdmin
}
