import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import { useParams, Link } from 'react-router-dom'
import { Row, Col, Alert, Container, CardBody ,Card} from "reactstrap"
import { ACTIVATED_USER } from '../../helpers/url_helper'
import JwtService from '../../helpers/jwtService';
import logoActive from "../../assets/images/logo-active.png";

const VerifyUser = props => {

    const params = useParams();
    const [isVerifying, setVerify] = useState(true);
    const [showAlert, setAlert] = useState(false);
    const [showErrorAlert, setErrorAlert] = useState(false);
    
    const [alertMessage, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState();

    console.log('token', params, props);
    useEffect(() => {
        document.body.className = "authentication-bg";
        // remove classname when component will unmount
        return function cleanup() {
        document.body.className = "";
        };
    });

    useEffect(() => {
        let {token} = params;
        JwtService.put(`${ACTIVATED_USER}/${token}`).then(res => {
            console.log('res', res);
            if(res.codes == 200) {
                setVerify(false)
                setMessage(res.message);
                setAlert(true);
                setTimeout(() => {
                    props.history.push('/plan');
                }, 2000)
            }
            props.history.push('/plan');
            if(res.code == 401) {
                setVerify(false)
                setErrorMessage(res.message);
                // setErrorAlert(true);
            }
        })
    }, [])
    return (
        <React.Fragment>
            <div className="my-5 pt-sm-5">
                <Container>

                    <Row>
                        <div className="col-12 text-center">
                        <div className="home-wrapper">
                            <Row>
                                <Col lg={12}>
                                <div className="text-center">
                                    <Link to="/" className="mb-5 d-block auth-logo">
                                    <img src={logoActive} alt="" height="50" className="logo logo-dark" />
                                    {/* <img src={logolight} alt="" height="22" className="logo logo-light" /> */}
                                    </Link>
                                </div>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                            
                                <Col sm={12}>
                                    <h4 className="my-3">{isVerifying ? "Verifying User..." : ""}</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Alert color="success" isOpen={showAlert} toggle={() => setAlert(false)} fade={false}>
                                        {alertMessage}
                                    </Alert>
                                    {errorMessage && <Alert color="danger" fade={false}>
                                        {errorMessage}
                                    </Alert>}
                                </Col>
                            </Row>
                            
                        </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

VerifyUser.propTypes = {
  history: PropTypes.object,
}



export default VerifyUser;
