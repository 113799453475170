import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Row, Col, CardBody, Card, Alert, Container, Modal, Table } from "reactstrap"



// Redux
import { connect } from "react-redux"
import { Link } from "react-router-dom"

// import images
import logo from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import logoActive from "../../assets/images/logo-active.png";
import JwtService from "../../helpers/jwtService";
import { BUY_PLAN, GET_PLAN } from "../../helpers/url_helper"

const Plan = props => {
    const [plans, setPlans] = useState([]);
    const [visible, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        document.body.className = "authentication-bg";
        // remove classname when component will unmount
        return function cleanup() {
            document.body.className = "";
        };
    });

    useEffect(() => {
        setLoader(true);
        JwtService.get(GET_PLAN).then(res => {
            
            setPlans(res);
            setLoader(false);
        });
    }, [])

    const buyPlan = (id) => {
        
        let userId = parseInt(localStorage.getItem('userId'));
        JwtService.post(BUY_PLAN, {planId: id, businessId: userId}).then(res => {
            
            if(res.code == 200) {
                setAlertMessage(res.message);
                setAlert(true);
                localStorage.removeItem('userId')
                setTimeout(() => {
                    props.history.push("/login");
                }, 2000)
            }
            
        })
    }
    return (
        <React.Fragment>
            <div className="pt-sm-5">
                <Container>
                    <Row>
                        <div className="col-12 text-center">
                            <div className="home-wrapper">
                                <Link to="/" className="mb-5 d-block auth-logo">
                                    <img src={logoActive} alt="" height="50" className="logo logo-dark" />
                                </Link>

                                {/* <h3 className="mt-5">Select Plan</h3> */}
                                {/* <p>Please check back in sometime.</p> */}
                                {/* <Row>
                                    <Col md={4}>
                                        <Card className="mt-4 maintenance-box">
                                            <CardBody className="p-4">
                                                <div className="avatar-sm mx-auto mb-4">
                                                    <div className="avatar-title rounded-circle bg-soft-primary text-primary font-size-20">
                                                        <i className="uil uil-cloud-wifi"></i>
                                                    </div>
                                                </div>
                                                <h5 className="font-size-15 text-uppercase">STANDART</h5>
                                                <h3 className="text-center text-uppercase">$23</h3>
                                                <p className="text-muted mb-0">There are many variations of passages of
                                                    Lorem Ipsum available, but the majority have suffered alteration.</p>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md={4}>
                                        <Card className="mt-4 maintenance-box">
                                            <CardBody className="p-4">
                                                <div className="avatar-sm mx-auto mb-4">
                                                    <div className="avatar-title rounded-circle bg-soft-primary text-primary font-size-20">
                                                        <i className="uil uil-clock"></i>
                                                    </div>
                                                </div>
                                                <h5 className="font-size-15 text-uppercase">BUSINESS</h5>
                                                <h3 className="text-center text-uppercase">$23</h3>
                                                <p className="text-muted mb-0">Contrary to popular belief, Lorem Ipsum is not
                                                    simply random text. It has roots in a piece of classical.</p>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md={4}>
                                        <Card className="mt-4 maintenance-box">
                                            <CardBody className="card-body p-4">
                                                <div className="avatar-sm mx-auto mb-4">
                                                    <div className="avatar-title rounded-circle bg-soft-primary text-primary font-size-20">
                                                        <i className="uil uil-envelope-alt"></i>
                                                    </div>
                                                </div>
                                                <h5 className="font-size-15 text-uppercase">ULTIMITED</h5>
                                                <h3 className="text-center text-uppercase">$23</h3>
                                                <p className="text-muted mb-0">If you are going to use a passage of Lorem
                                                    Ipsum, you need to be sure there isn't anything embar..
                                                    <Link to="mailto:no-reply@domain.com"
                                                        className="text-decoration-underline"> no-reply@domain.com</Link></p>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row> */}
                                <Row>
                                    <Col>
                                        <div class="sec-title text-center">
                                            <span class="title">Get plan</span>
                                            <h3>Choose a Plan</h3>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="justify-content-md-center">
                                    <Col lg={6}>
                                        <Alert color="success" isOpen={visible} toggle={() => setAlert(false)} fade={false}>
                                            {alertMessage}
                                        </Alert>
                                    </Col>
                                </Row>
                                <Row>
                                    {loader && <div id="preloader" className="position-relative">
                                    <div id="status">
                                        <div className="spinner">
                                        <i className="uil-shutter-alt spin-icon"></i>
                                        </div>
                                    </div>
                                </div>}
                                </Row>
                                <div className="row justify-content-md-center">
                                    
                                    {/* <div className="pricing-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
                                        <div className="inner-box">

                                            <div className="price-box">
                                                <div className="title"> STANDART</div>
                                                <h4 className="price">$35.99</h4>
                                            </div>
                                            <ul className="features">
                                                <li className="true">Conference plans</li>
                                                <li className="true">Free Lunch And Coffee</li>
                                                <li className="true">Certificate</li>
                                                <li className="false">Easy Access</li>
                                                <li className="false">Free Contacts</li>
                                            </ul>
                                            <div>
                                                <a  className="btn btn-primary">BUY plan</a>
                                            </div>
                                        </div>
                                    </div> */}

                                    
                                    {plans.length > 0 && plans.map(plan => {
                                        return (<div className="pricing-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="400ms">
                                        <div className="inner-box">

                                            <div className="price-box">
                                                <div className="title">{plan.planName}</div>
                                                <h4 className="price">{new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'GBP' }).format(plan.planPrice)}</h4>
                                            </div>
                                            <ul className="features">
                                                <li className="true">Conference plans</li>
                                                <li className="true">Free Lunch And Coffee</li>
                                                <li className="true">Certificate</li>
                                                <li className="true">Easy Access</li>
                                                <li className="false">Free Contacts</li>
                                            </ul>
                                            <div className="text-center">
                                                <a  className="btn btn-primary" onClick={() => buyPlan(plan.id)}>BUY plan</a>
                                            </div>
                                        </div>
                                    </div>)
                                    })}

                                    
                                    {/* <div className="pricing-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="800ms">
                                        <div className="inner-box">

                                            <div className="price-box">
                                                <div className="title">ULTIMITED</div>
                                                <h4 className="price">$199.99</h4>
                                            </div>
                                            <ul className="features">
                                                <li className="true">Conference plans</li>
                                                <li className="true">Free Lunch And Coffee</li>
                                                <li className="true">Certificate</li>
                                                <li className="true">Easy Access</li>
                                                <li className="true">Free Contacts</li>
                                            </ul>
                                            <div>
                                                <a className="btn btn-primary">BUY plan</a>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

Plan.propTypes = {

}

export default Plan;
