
import axios from 'axios';

const BASE_API_URL = "https://connect.e-doconline.co.uk"
const headers = {
	headers: {
		'Content-Type': 'application/json'
	}
}
class JwtService {
	init() {
		this.setInterceptors();
		// axios.defaults.headers.common['responseCode'] = 'starfolioapi';
	}



	setInterceptors = () => {
		axios.interceptors.response.use(
			response => {

				return response;
			},
			err => {

				return new Promise((resolve, reject) => {
					if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
						// if you ever get an unauthorized response, logout the user
						// window.location = "/logout";
					}
					throw err;
				});
			}
		);
	};

	getWithParams = (url, params) => {

		// const token = 'token'
		// let header = {};
		// if(token) {
		// 	header.KEY = token;
		// }
		let authUser = localStorage.getItem('authUser');
		let headers = {};
		if (authUser) {
			let token = JSON.parse(localStorage.getItem('authUser')).data.accessToken;
			headers['Authorization'] = `Bearer ${token}`
		}

		return fetch((`${BASE_API_URL}${url}`), { method: 'GET', headers: { 'Content-Type': 'application/json', ...headers } }).then(response => response.json()).then(data => data).catch(err => window.location = "/logout");
	};

	post = (url, data) => {
		let authUser = localStorage.getItem('authUser');
		let headers = {};
		if (authUser) {
			let token = JSON.parse(localStorage.getItem('authUser')).data.accessToken;
			headers['Authorization'] = `Bearer ${token}`
		}
		return fetch((`${BASE_API_URL}${url}`), {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', ...headers },
			body: JSON.stringify(data)
		}).then(response => response.json()).
			then(data => data).catch(err => {
				console.log(err);
				// window.location = "/logout"
			}
			);
	};

	get = (url) => {
		let authUser = localStorage.getItem('authUser');
		let headers = {};
		if (authUser) {
			let token = JSON.parse(localStorage.getItem('authUser')).data.accessToken;
			headers['Authorization'] = `Bearer ${token}`
		}
		return fetch((`${BASE_API_URL}${url}`), {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', ...headers }
		}).then(response => response.json())
			.then(data => data)
			.catch(err => {
				console.log(err)
				// window.location = "/logout"
			}
			);
	};

	put = (url) => {
		let authUser = localStorage.getItem('authUser');
		let headers = {};
		if (authUser) {
			let token = JSON.parse(localStorage.getItem('authUser')).data.accessToken;
			headers['Authorization'] = `Bearer ${token}`
		}
		return fetch((`${BASE_API_URL}${url}`), { method: 'PUT', headers: { 'Content-Type': 'application/json', ...headers } }).then(response => response.json()).then(data => data).catch(err => window.location = "/logout");
	};
}

const instance = new JwtService();

export default instance;
