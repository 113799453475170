export const  generateGUID = () => {
    function S4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
  
    return S4() + S4();
}
export function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

export const getLogedinUser = () => {
  return parseJwt(JSON.parse(localStorage.getItem('authUser')).data.accessToken)
}

export const getLoggedInUserType = () => {
  return JSON.parse(localStorage.getItem('authUser')).data.userType;
}