import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_ROLES, GET_USER_PROFILE } from "./actionTypes"
import {
  getUsersSuccess,
  getUsersFail,
  getUserProfileSuccess,
  getUserProfileFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getRoles } from "../../helpers/fakebackend_helper"

function* fetchRoles() {
  try {
    const response = yield call(getRoles)
    yield put(getUsersSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* rolesSaga() {
  yield takeEvery(GET_ROLES, fetchRoles)
}

export default rolesSaga
