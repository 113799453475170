import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Row, Modal, Button, Badge, Alert } from "reactstrap"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import { AvForm, AvField, AvCheckbox, AvCheckboxGroup, AvBaseInput } from "availity-reactstrap-validation"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import Table from '../../../components/Table';
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
// phone number 
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'
import { assignPermissionSecondaryUser, getAdminUsers } from "../../../helpers/fakebackend_helper"
import contactListColumns from "./adminSettingColumns"
// import { columns } from "./adminSettingColumns"
import { isEmpty, values } from "lodash"
import JwtService from "../../../helpers/jwtService";
import { ACTIVATE_SECONDARY_USER, ADD_SECONDARY_USER } from "../../../helpers/url_helper";

const ContactsList = props => {
  const [userList, setUserList] = useState([])
  const [totalSize, setTotalSize] = useState(0)
  const [loading, setLoading] = useState(false);
  const [modal_standard, setmodal_standard] = useState(false)
  const [showUserModal, setShowUserModal] = useState(false)
  const [secondaryUserModel, setSecondaryModel] = useState()
  const [phoneNumber, setPhoneNumber] = useState();
  const [alert, setAlert] = useState({variant: "", message: ""});
  const [showAlert, setShowAlert] = useState(false);
  const defaultSort = "firstName";
  const defaultSortDirection = "ASC";
  const [pageOption, setPageOption] = useState({
    page: 1,
    limit: 10,
    sortBy: defaultSort,
    sortDirection: defaultSortDirection,
    search: ""
  })
  const { SearchBar } = Search

  const getAdminUserList = () => {
    setLoading(true);
    getAdminUsers(pageOption).then(res => {
      if (res.codes == 200) {
        setUserList(res?.data?.items || [])
        setLoading(false);
        setTotalSize(parseInt(res?.data.total ? res?.data.total : 10))
      } else {
        setLoading(false);
      }
    }).catch()
  }
  useEffect(() => {
    getAdminUserList();
  }, [pageOption])
  // useEffect(() => {
  //   if (!isEmpty(users)) {
  //     setUserList(users)
  //   }
  // }, [users])

  const columns = [
    {
      dataField: 'firstName',
      text: 'First Name',
      sort: true
    },
    {
      dataField: 'lastName',
      text: 'Last Name',
      sort: false
    },
    {
      dataField: 'email',
      text: 'Email',
      sort: false
    },
    {
      dataField: 'isActive',
      text: 'Status',
      sort: false,
      formatter: (row, data) => {
        return <Badge
          className={`badge bg-pill font-size-12 bg-soft-${row ? "success" : "danger"}`}
          color={row ? "success" : "danger"}
        >
          {row ? "Active" : 'Inactive'}
        </Badge>
      }
    },
    {
     
      text: 'Action',
      sort: false,
      formatter: (row, data) => {

        return <Button color="primary" size="small" onClick={() => { setSecondaryModel(data); setmodal_standard(true) }}>
          <i className="uil uil-link"></i>
        </Button>
      }
    }
  ];


  // open modal
  function tog_standard() {
    setmodal_standard(!modal_standard)
    // setSecondaryModel();

  }
  // user modal
  function userModalToggle() {
    setShowUserModal(!showUserModal)
    // setSecondaryModel();

  }

  const handleDirectorUser = (event, values) => {

    let params = {
      userId: 37,
      businessId: 46,
      permissionLevel: 2,
      secondaryUserId: 81
    }
    assignPermissionSecondaryUser(params).then(res => {

      setmodal_standard(false)
    }).catch(err => {
      console.log('error', err)
    })
  }
  var selectRowProp = {
    mode: "checkbox",
    clickToSelect: true,
  };

  const handleTableChangeT = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
    // console.log('on table changes', page, sizePerPage, sortField, sortOrder, searchText);
    setPageOption({
      page: page,
      limit: sizePerPage,
      sortBy: sortField != null ? sortField : defaultSort,
      sortDirection: sortOrder != undefined ? sortOrder : defaultSortDirection,
      search: searchText
    })
  }
  // console.log('userList', userList);
  const activateSecondaryUser = () => {
    
   
    let values = {email: secondaryUserModel.email}
    JwtService.post(ACTIVATE_SECONDARY_USER, values).then(res => {
      if(res.codes == 200) {
       
        setAlert({variant: "success", message: res.message})
        setShowAlert(true);

        setTimeout(() => {
          setmodal_standard(false);
          getAdminUserList();
        }, 1000)
      }else {
        setAlert({variant: "danger", message: res.message})
        setShowAlert(true);
      }
    }).catch(error => {
      console.log('error', error)
    });
  }
  const handleValidSubmit = (event, values) => { 

    

    values['phoneNumber'] = phoneNumber;

    JwtService.post(ADD_SECONDARY_USER, values).then(res => {
      if(res.codes == 200) {
       
        setAlert({variant: "success", message: res.message})
        setShowAlert(true);

        setTimeout(() => {
          setShowUserModal(false);
          getAdminUserList();
          setAlert({variant: "", message: ''})
          setPhoneNumber()
          setShowAlert(false);
        }, 1000)
      }else {
       
        setAlert({variant: "danger", message: res.message})
        setShowAlert(true);
      }
    }).catch(error => {
      console.log('error', error)
    });
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <div className="position-relative">
            <Breadcrumbs title="Admin" breadcrumbItem="Admin Settings" />
            <Button 
              variant="primary" 
              color="primary" 
              className="position-absolute add-secondary-user"
              onClick={() => {
                setShowUserModal(true)
              }}
            >
                <i className="uil uil-plus"></i> Add Secondary User
              </Button>
          </div>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Table
                    keyField='id'
                    data={userList}
                    columns={columns}
                    page={pageOption.page}
                    sizePerPage={pageOption.limit}
                    onTableChange={handleTableChangeT}
                    noDataIndication={'No Data Found'}
                    loading={loading}
                    isPagination={true}
                    isSearch={true}
                    totalSize={totalSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            isOpen={modal_standard}
            toggle={() => {
              tog_standard()
            }}
          >
           
              <div className="modal-header">
                <h6 className="modal-title mt-0" id="myModalLabel">
                  Activate User
                </h6>
                <button
                  type="button"
                  onClick={() => {
                    setmodal_standard(false)
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <Row>
                  <Col>
                    <Alert color={alert.variant} isOpen={showAlert} toggle={() => setShowAlert(false)} fade={false}>
                      {alert.message}
                    </Alert>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h5>Are you sure you want to send an activation link ?</h5>
                  </Col>
                </Row>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    tog_standard()
                  }}
                  className="btn btn-secondary waves-effect"
                  data-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  disabled={secondaryUserModel && secondaryUserModel.isActive}
                  onClick={() => {
                    activateSecondaryUser();
                  }}
                >
                  Yes
                </button>
              </div>
            
          </Modal>
          <Modal
            isOpen={showUserModal}
            toggle={() => {
              userModalToggle()
            }}
          >
             <AvForm
                  className="form-horizontal"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v)
                  }}
                >
              <div className="modal-header">
                <h6 className="modal-title mt-0" id="myModalLabel">
                  Add Secondary User
                </h6>
                <button
                  type="button"
                  onClick={() => {
                    setShowUserModal(false)
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
             
                <Row>
                  <Col>
                    <Alert color={alert.variant} isOpen={showAlert} toggle={() => setShowAlert(false)} fade={false}>
                      {alert.message}
                    </Alert>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <div className="hidden">
                      <AvField
                        name="userType"
                        label="First Name"
                        type="text"
                        value="S"
                        placeholder="Enter first name"
                        className="hidden"
                      />
                      <AvField
                        name="designation"
                        label="First Name"
                        type="text"
                        value="Director"
                        className="hidden"
                        placeholder="Enter first name"
                      />
                    </div>

                    <div className="mb-3">
                      <AvField
                        name="firstName"
                        label="First Name"
                        type="text"

                        placeholder="Enter first name"
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <AvField
                        name="lastName"
                        label="Last Name"
                        type="text"

                        placeholder="Enter last name"
                      />
                    </div>
                  </Col>
                </Row>
                <div className="mb-3">
                  <AvField
                    name="email"
                    label="Email Address"
                    type="email"
                    errorMessage="Please enter email"
                    placeholder="Enter email address"
                  />
                </div>
                <div className="form-group mb-3">
                  <label>Phone Number</label>
                  <PhoneInput
                    name= "phoneNumber"
                    placeholder="Enter phone number"
                    value={phoneNumber}
                    onChange={(value) => setPhoneNumber(value)}/>
                </div>
                
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    userModalToggle()
                  }}
                  className="btn btn-secondary waves-effect"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary waves-effect waves-light"
                  // disabled={secondaryUserModel && secondaryUserModel.isActive}
                  
                >
                  Submit
                </button>
              </div>
              </AvForm>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

ContactsList.propTypes = {

}

export default withRouter(ContactsList)
