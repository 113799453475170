import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Row, Col, CardBody, Card, Alert, Container, Modal, Table, Button } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import JwtService from "../../helpers/jwtService"
// import images
import logo from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import logoActive from "../../assets/images/logo-active.png";
import { ACCEPT_TERM } from "../../helpers/url_helper"
const TermCondition = props => {
    const [visible, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const termCondition = () => {
        let userId = parseInt(localStorage.getItem('userId'));
        JwtService.put(`${ACCEPT_TERM}${userId}`).then(res => {
            console.log('buy plan success', res);
            if(res.code == 200) {
                setAlertMessage(res.message);
                setAlert(true);
                setTimeout(() => {
                    setAlert(false);
                    props.history.push("/plan");
                }, 2000)
            }
            
        })
    }
    return (
        <React.Fragment>
            <div className="account-pages pt-sm-5">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="text-center">
                                <Link to="/" className="mb-5 d-block auth-logo">
                                    <img src={logoActive} alt="" height="50" className="logo logo-dark m-auto" />
                                    {/* <img src={logolight} alt="" height="22" className="logo logo-light" /> */}
                                </Link>
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center justify-content-center">
                        <Col md={8} lg={6} xl={5}>

                            <Card>
                                <CardBody className="p-4">
                                    <div className="text-center mt-2">
                                        <h5 className="text-primary">Welcome to EDOC</h5>
                                        <p className="text-muted">Read below term and Condition</p>
                                    </div>
                                    <Row>
                                        <Col>
                                            <Alert color="success" isOpen={visible} toggle={() => setAlert(false)} fade={false}>
                                                {alertMessage}
                                            </Alert>
                                        </Col>
                                    </Row>
                                    <div className="text-center">
                                        <Button className="btn btn-primary m-auto" onClick={() => termCondition()}> Accept T&C</Button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

TermCondition.propTypes = {

}

export default TermCondition;
