import PropTypes from 'prop-types'
import React, { useEffect } from "react"
import { Row, Col, Alert, Container, CardBody ,Card, Label, Input} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// action
import { userForgetPassword } from "../../store/actions"

// import images
import logo from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import logoActive from "../../assets/images/logo-active.png"

const ResetPassword = props => {
  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  function handleValidSubmit(event, values) {
    props.userForgetPassword(values, props.history)
  }

  return (
    <React.Fragment>
      {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="mdi mdi-home-variant h2"></i>
        </Link>
      </div> */}

      <div className="account-pages my-5  pt-sm-5">
        <Container>
          <div className="row justify-content-center">

            <div className="col-md-8 col-lg-6 col-xl-5">
              <div>

                <a href="/" className="mb-5 d-block auth-logo">
                  <img src={logoActive} alt="" height="50" className="logo logo-dark" />
                </a>
                <Card>

                  <CardBody className="p-4">

                    <div className="text-center mt-2">
                      <h5 className="text-primary">Reset Password</h5>
                      <p className="text-muted">Create new password to proceed.</p>
                    </div>
                    <div className="p-2 mt-4">
                      
                      {props.forgetError && props.forgetError ? (
                        <Alert color="danger" className="text-center mb-4" style={{ marginTop: "13px" }}>
                          {props.forgetError}
                        </Alert>
                      ) : null}
                      {props.forgetSuccessMsg ? (
                        <Alert color="success" className="text-center mb-4" style={{ marginTop: "13px" }}>
                          {props.forgetSuccessMsg}
                        </Alert>
                      ) : null}

                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                      >
                        <div className="col-12 mb-3">
                          <Label className="visually-hidden" htmlFor="resetcode">Password reset code</Label>
                          <div className="input-group">
                            <Input type="password" className="form-control" id="resetcode" placeholder="Reset Code" />
                            <div className="input-group-text"><i className="uil uil-eye-slash"></i></div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <Label className="visually-hidden" htmlFor="password">Password</Label>
                          <div className="input-group">
                            <Input type="password" className="form-control" id="password" placeholder="Password" />
                            <div className="input-group-text"><i className="uil uil-eye-slash"></i></div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <Label className="visually-hidden" htmlFor="confirmpassword">Password</Label>
                          <div className="input-group">
                            <Input type="password" className="form-control" id="confirmpassword" placeholder="Confirm Password" />
                            <div className="input-group-text"><i className="uil uil-eye-slash"></i></div>
                          </div>
                        </div>
                        
                        <Row className="row mb-0">
                          <Col className="col-12 text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              Reset My Password
                          </button>
                          </Col>
                        </Row>
                        <div className="mt-4 text-center">
                          <p className="mb-0">Remember It ? <Link to="/login" className="fw-medium text-primary"> Signin </Link></p>
                        </div>
                      </AvForm>
                    </div>

                  </CardBody>
                </Card>
                {/* <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} Minible. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Themesbrand
                </p>
                </div> */}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

ResetPassword.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func
}

const mapStatetoProps = state => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword
  return { forgetError, forgetSuccessMsg }
}

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(ResetPassword)
)
