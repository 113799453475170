import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { REGISTER_USER } from "./actionTypes"
import { registerUserSuccessful, registerUserFailed, registerUserReset } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeRegister,
  postJwtRegister,
} from "../../../helpers/fakebackend_helper"

// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend()

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user, history } }) {
  console.log('user', user)
  try {
      const response = yield call(postJwtRegister, user)
      if(response.codes == 200){
        let id = response.message.match(/(\d+)/);
        localStorage.setItem('userId', id);
        yield put(registerUserSuccessful(response));
        yield put(registerUserReset());
        history.push("/registration-success")
      }
      else 
        yield put(registerUserFailed(response))
    
  } catch (error) {
    yield put(registerUserFailed(error))
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
