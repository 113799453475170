import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import Select from "react-select"
import { Button, Card, CardBody, Col, Container, Label, Row, Form, Input, Badge, Alert, Modal } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
// import { getOrders, getClients } from "../../store/actions";
import { getClients } from "../../helpers/fakebackend_helper"
import Columns from "./Columns";
import Table from '../../components/Table';


import { getLogedinUser } from "../../helpers/commonFunctions"
import JwtService from "../../helpers/jwtService"
import { ASSIGN_PERMISSION_SECONDARY_USER, GET_SECONDARY_USERS } from "../../helpers/url_helper"

const EcommerceOrders = props => {
  const [orders, setOrders] = useState([]);
  const [modal, setModal] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [modal_standard, setmodal_standard] = useState(false);
  const [secondaryUserModel, setSecondaryModel] = useState();
  const [alert, setAlert] = useState({variant: "", message: ""});
  const [showAlert, setShowAlert] = useState(false);
  const [secondaryUserList, setSecondaryUserList] = useState([]);
  const [selectedUser, setSelectedSecondaryUser] = useState();
  // const pageOptions = {
  //   sizePerPage: 10,
  //   totalSize: 50, // replace later with size(orders),
  //   custom: true,
  // }
  const defaultSort = "userFirstName";
  const defaultSortDirection = "ASC";
  const [pageOption, setPageOption] = useState({
    page: 1,
    limit: 10,
    sortBy: "userFirstName",
    sortDirection: "ASC",
    search: ""
  })
  // const pageData = {
  //   "page": 1,
  //   "limit": 10,
  //   "sortBy":"firstName",
  //   "sortDirection":"ASC",
  //   "search":""
  // }

  const getClientList = () => {
    setLoading(true);
    getClients(pageOption).then(res => {
      
      if (res.codes == 200) {
        setLoading(false);
        setOrders(res.data.items);
        setTotal(parseInt(res.data.total))
      }else {
        setOrders([]);
        setLoading(false);
      }
    }).catch(err => {
      setLoading(false);
     
    })
  }
  useEffect(() => {
    getClientList();
  }, [pageOption])

  useEffect(() => {
    let values = {
      "page": 1,
      "limit": 10000,
      "sortBy":"firstName",
      "sortDirection":"ASC",
      "search":""
    }
    JwtService.get(GET_SECONDARY_USERS).then(res => {
      if(res.codes == 200) {
        
        let options = res?.data.map(item => {
          return {
            ...item,
            value: item.id,
            label: `${item?.firstName} ${item?.lastName}`
          }
        })
        if(options.length > 0) {
          setSelectedSecondaryUser(options[0])
          setSecondaryUserList(options)
        }else {
          setSecondaryUserList([])
        }
      }
    })
  }, [])
  
  useEffect(() => {
    
    setOrderList(orders)
  }, [orders])

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setOrderList(
      orders.filter(order =>
        Object.keys(order).some(
          key =>
            typeof order[key] === "string" &&
            order[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    )
  }

  // open modal
  function tog_standard() {
    setmodal_standard(!modal_standard)
    // setSecondaryModel();

  }

  const toggleModal = () => {
    setModal(!modal)
  }

  const handleTableChangeT = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
   
    setPageOption(prevState => ({
      page: page,
      limit: sizePerPage,
      sortBy: sortField != null ? sortField : defaultSort,
      sortDirection: sortOrder != undefined ? sortOrder : defaultSortDirection ,
      search: searchText
    }))
  }

  const columns = [
    {
        dataField: 'userId',
        text: 'Reference Id',
        sort: true,
        formatter: (row, data) => {
          return <Link to={`/client/dashboard/${row}?name=${data.userFirstName+" "+data.userLastName}`} className="text-dark fw-bold">
          {`EDOC-${row}`}
        </Link>
        }
    },
    {
        dataField: 'userFirstName',
        text: 'First Name',
        sort: true
    },
    {
        dataField: 'userLastName',
        text: 'Last Name',
        sort: false
    },
    {
      dataField: 'isActive',
      text: 'Status',
      sort: false,
      formatter: (row) => {
       
        return <Badge
          className={`badge bg-pill font-size-12 bg-soft-${row? "success" : "danger"}`}
          color={row ? "success" : "danger"}
        >
          {row ? "Active" : 'Suspended'}
        </Badge>
      }
    },
    {
     
      text: 'Action',
      sort: false,
      formatter: (row, data) => {

        return <Button color="primary" size="small" onClick={() => { setSecondaryModel(data); setmodal_standard(true) }}>
          <i className="uil uil-link"></i>
        </Button>
      }
    }
  ];

  const activateSecondaryUser = () => {
    let values = {
      "userId": parseInt(secondaryUserModel.userId),
      // "businessId": secondaryUserModel.businessUserId,
      "permissionLevel": 1,
      "secondaryUserId" : selectedUser.id
    }
    JwtService.post(ASSIGN_PERMISSION_SECONDARY_USER, values).then(res => {
      if(res.codes == 200) {
        setAlert({variant: "success", message: res.message})
        setShowAlert(true);

        setTimeout(() => {
          setmodal_standard(false);
          getClientList();
        }, 1000)
      }else {
        setAlert({variant: "danger", message: res.message})
        setShowAlert(true);
      }
    }).catch(error => {
      console.log('error', error)
    });
  }
  return (
    <React.Fragment>
     
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="" breadcrumbItem="Client List" />
          <Row>
            <Col xs="12">
              <Card>

                <CardBody>
                  <Table
                    keyField='id'
                    data={orderList}
                    sizePerPage={pageOption.limit}
                    page={pageOption.page}
                    columns={columns}
                    onTableChange={handleTableChangeT}
                    noDataIndication={'No Data Found'}
                    loading={loading}
                    isPagination={true}
                    isSearch={true}
                    totalSize={total}
                  />
                </CardBody>

              </Card>
            </Col>
          </Row>
          <Modal
            isOpen={modal_standard}
            toggle={() => {
              tog_standard()
            }}
          >
           
              <div className="modal-header">
                <h6 className="modal-title mt-0" id="myModalLabel">
                  Delegate permission to user ?
                </h6>
                <button
                  type="button"
                  onClick={() => {
                    setmodal_standard(false)
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <Row>
                  <Col>
                    <Alert color={alert.variant} isOpen={showAlert} toggle={() => setShowAlert(false)} fade={false}>
                      {alert.message}
                    </Alert>
                  </Col>
                </Row>
                
                
                <Row>
                  <Col>
                  <Select
                    classNamePrefix="select"
                    defaultValue={secondaryUserList[0]}
                    
                    isClearable={true}
                    isSearchable={true}
                    name="color"
                    options={secondaryUserList}
                    onChange={(value) => {
                      setSelectedSecondaryUser(value)
                    }}
                  />
                  </Col>
                </Row>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    tog_standard()
                  }}
                  className="btn btn-secondary waves-effect"
                  data-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                 
                  onClick={() => {
                    activateSecondaryUser();
                  }}
                >
                  Yes
                </button>
              </div>
            
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

EcommerceOrders.propTypes = {

}

// const mapStateToProps = (state) => {

//   return ({
//     orders: state.clients.clients,
//   })
// }

// const mapDispatchToProps = dispatch => ({
//   onGetOrders: () => dispatch(getClients()),
// })

export default withRouter(EcommerceOrders)
