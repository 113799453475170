/* USERS */
export const GET_ADMIN_USERS = "GET_ADMIN_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAIL = "GET_USERS_FAIL"

/* USERS PROFILE */
export const GET_USER_PROFILE = "GET_USER_PROFILE"
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS"
export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL"

export const REGISTER_ADMIN = "REGISTER_ADMIN"
export const REGISTER_ADMIN_USER_SUCCESSFUL = "REGISTER_ADMIN_USER_SUCCESSFUL"
export const REGISTER_ADMIN_USER_FAILED = "REGISTER_ADMIN_USER_FAILED"