//REGISTER
export const POST_FAKE_REGISTER = "/api/v1/businessPartner/registerBusinessPartner"

//REGISTER
export const POST_ADMIN_REGISTER = "/api/v1/businessPartner/registerBusinessPartner"

// activate user
export const ACTIVATED_USER = "/api/v1/businessPartner/activateBusinessPartnerUsers"

// assign permission seconday user
export const ASSIGN_PERMISSION_SECONDARY_USER = '/api/v1/entitlement/assignPermissionToSecondaryUser'
//LOGIN
// export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_LOGIN = "/api/v1/businessPartner/logIn"

export const POST_FAKE_JWT_LOGIN = "/api/v1/businessPartner/logIn"
export const POST_FAKE_PASSWORD_FORGET = "/api/v1/businessPartner/sendChangePasswordLink"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/api/v1/businessPartner/sendChangePasswordLink"
export const SOCIAL_LOGIN = "/social-login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//PRODUCTS
export const GET_PRODUCTS = "/products"
export const GET_PRODUCTS_DETAIL = "/product"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//CHATS
export const GET_CHATS = "/chats"
export const GET_GROUPS = "/groups"
export const GET_CONTACTS = "/contacts"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"

//ORDERS
export const GET_ORDERS = "/api/v1/businessPartner/getPlans"

//ORDERS
export const GET_CLIENTS = "/api/v1/entitlement/clients/list"

//ROLES
export const GET_ROLES = "/api/v1/businessPartner/getPlans"

//CART DATA
export const GET_CART_DATA = "/cart"

//CUSTOMERS
export const GET_CUSTOMERS = "/customers"

//SHOPS
export const GET_SHOPS = "/shops"

//CRYPTO
export const GET_WALLET = "/wallet"
export const GET_CRYPTO_ORDERS = "/crypto/orders"

//INVOICES
export const GET_INVOICES = "/invoices"
export const GET_INVOICE_DETAIL = "/invoice"

//PROJECTS
export const GET_PROJECTS = "/projects"
export const GET_PROJECT_DETAIL = "/project"

//TASKS
export const GET_TASKS = "/tasks"

//CONTACTS
export const GET_USERS = "/api/v1/businessPartner/getBusinessPartners"
export const GET_USER_PROFILE = "/user"

//admin setting
// export const GET_ADMIN_USERS = "/api/v1/businessPartner/getPlans"

export const GET_ADMIN_USERS = "/api/v1/businessPartner/getSecondaryUsersByPrimaryUserId"
export const GET_SECONDARY_USERS = "/api/v1/businessPartner/getSecondaryUsersListByPrimaryUserId"

 
// plan
export const GET_PLAN = "/api/v1/businessPartner/getPlans";

// Buy plan
export const BUY_PLAN = "/api/v1/businessPartner/attachPlan"

// accept term and condition
export const ACCEPT_TERM = "/api/v1/businessPartner/acceptBusinessPartnerTnc/"

// change password 
export const CHANGE_PASSWORD = "/api/v1/businessPartner/changePassword"

// activate secondary users
export const ACTIVATE_SECONDARY_USER = "/api/v1/businessPartner/activateSecondaryUser";

// add secondary user
export const ADD_SECONDARY_USER = '/api/v1/businessPartner/registerSecondaryUser'


export const BANK_LIST = '/api/v1/partner-banking/clientConsents'

export const ACCOUNT_BY_BANK = '/api/v1/partner-banking/accounts'

export const DASHBOARD_DETAIL = '/api/v1/partner-dashboard/user'

